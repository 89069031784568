import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const StoreIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'black', className }) => {
  const color1 = getHexColor(color)
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 6a.97.97 0 0 1-.713-.287A.97.97 0 0 1 4 5a.97.97 0 0 1 .287-.713A.97.97 0 0 1 5 4h14c.284 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 19 6H5Zm0 14a.968.968 0 0 1-.713-.288A.967.967 0 0 1 4 19v-5h-.175a.96.96 0 0 1-.775-.363c-.2-.241-.266-.52-.2-.837l1-5a.99.99 0 0 1 .35-.575A.96.96 0 0 1 4.825 7h14.35a.96.96 0 0 1 .625.225c.183.15.3.342.35.575l1 5a.943.943 0 0 1-.2.837.96.96 0 0 1-.775.363H20v5c0 .283-.096.52-.288.712A.965.965 0 0 1 19 20a.965.965 0 0 1-.712-.288A.965.965 0 0 1 18 19v-5h-4v5c0 .283-.095.52-.287.712A.968.968 0 0 1 13 20H5Zm1-2h6v-4H6v4Zm-.95-6h13.9l-.6-3H5.65l-.6 3Z"
        fill={color1}
      />
    </svg>
  )
}
