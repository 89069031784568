import { gql as contentfulGQL } from '@apollo/client'
import { Document } from '@contentful/rich-text-types'
import { ButtonVariant } from '@/atoms/Button'
import { TheatricalRegions } from '@/contexts/TheatricalRegionContext/TheatricalRegionTypes'
import { MovieJsonLdProps } from '@/layout/Seo/JsonLd'
import { ContentfulClientOptions, getContentfulClient } from '@/services/ApolloClient'
import { CMSCloudinaryImage, CMSWatchable, ContentfulSys } from '@/services/CmsService/types'
import { CriticsCollection, FilmDetails, TheatricalRegion } from '@/types/codegen-contentful'

export interface TheatricalReleaseStub {
  theatricalSlug: string
  theatricalName: string
  sys: ContentfulSys
}

export interface TheatricalMovie {
  region: string
  verticalPosters: TheatricalRegion['verticalPosters']
  projectName: string
  theatricalSlug: string
  filmDetails: FilmDetails
  releaseDate: Date
}

interface CMSTheatricalReleaseStubCollection {
  theatricalReleasesCollection: { items: TheatricalReleaseStub[] }
}

const getTheatricalRegionQuery = (page?: 'ticket' | 'pif') => {
  if (page && page === 'ticket') return getTheatricRegionBySlugAndRegionQueryTicketPage
  if (page && page === 'pif') return getTheatricRegionBySlugAndRegionQueryPifPage
  return getTheatricRegionBySlugAndRegionQuery
}

interface CMSTheatricalRelease {
  filmDetails: FilmDetails
  blacklistedVenueIDs?: string[] | null
  theatricalSlug: string
  theatricalName: string
  description: string
  maxDaysToShow: number
  projectSlug: string
  releaseDate: string
  endDate: string
  fathomEventsUrl?: string
  fandangoUrl?: string
  goalLineSlug?: string
  goalLineTitle?: string
  seoTitle?: string
  seoDescription?: string
  horizontalPosters: CMSCloudinaryImage[]
  initialChatMessages: string[]
  verticalPosters: CMSCloudinaryImage[]
  seoImages: CMSCloudinaryImage[]
  hypeBackground: CMSCloudinaryImage[]
  logo: CMSCloudinaryImage[]
  trailer?: CMSWatchable
  hypePartyCode?: string
  emergencyFandango: boolean
  emergencyAtom: boolean
  noSearchResultsMessages: NoSearchResultsMessage[]
  regionCollection: {
    items: TheatricalRegion[]
  }
  hypePinnedMessage?: {
    json: Document
  }
  theme: {
    colors: {
      primary: string
      onPrimary: string
      secondary: string
      onSecondary: string
      tertiary: string
      onTertiary: string
      notice: string
      onNotice: string
    }
  }
  jsonLd: MovieJsonLdProps
}

interface CMSTheatricReleaseCollection {
  theatricalReleasesCollection: { items: CMSTheatricalRelease[] }
}

export interface CMSTheatricRegionCollection {
  theatricalRegionCollection: { items: TheatricalRegion[] }
}

interface CMSTheatricRegionMovieCollection {
  theatricalRegionCollection: { items: TheatricalMovie[] }
}

export interface NoSearchResultsMessage {
  header: string
  message?: string
  startDisplayAt?: string
  endDisplayAt?: string
  buttonUrl?: string
  buttonText?: string
  buttonColor?: ButtonVariant
  buttonIcon?: string
  buttonIconColor?: string
}

export type CMSScene = {
  videoUri: string
  thumbnail: string
  id: string
  title: string
  alternateThumbnails: { id: string; thumbnail: string }[]
}

export type CMSShiftPremiere = {
  title: string
  description: string
}

export interface TheatricalRelease
  extends Omit<
    CMSTheatricalRelease,
    'verticalPosters' | 'horizontalPosters' | 'seoImages' | 'hypeBackground' | 'logo' | 'trailer' | 'regionCollection'
  > {
  horizontalPoster: CMSCloudinaryImage
  verticalPoster: CMSCloudinaryImage
  seoImage: CMSCloudinaryImage
  hypeBackground: CMSCloudinaryImage
  logo: CMSCloudinaryImage
  trailer?: CMSWatchable
  sceneData?: { data: CMSScene[] }
  shiftPremiere?: CMSShiftPremiere
  youtubeTrailerUrl?: string
  criticsCollection?: CriticsCollection

  /**
   * All regions that a theatrical release is in.
   */
  regionCollection: TheatricalRegion[] | []
  currentTheatricalMovies: TheatricalMovie[] | []

  /**
   * TODO: clarify what this collection is.
   */
  supportedTheatricalRegions: TheatricalRegion[] | []
}

const getAllTheatricReleasesQuery = contentfulGQL/* GraphQL */ `
  query getAllTheatricalReleases($preview: Boolean) {
    theatricalReleasesCollection(preview: $preview) {
      items {
        theatricalSlug
        theatricalName
        sys {
          publishedAt
          firstPublishedAt
          publishedVersion
        }
      }
    }
  }
`

export async function getAllTheatricalReleases(opts?: ContentfulClientOptions): Promise<TheatricalReleaseStub[]> {
  const contentfulClient = getContentfulClient(opts)

  const { data } = await contentfulClient.query<CMSTheatricalReleaseStubCollection, { preview?: boolean }>({
    query: getAllTheatricReleasesQuery,
    variables: {
      preview: opts?.preview ?? false,
    },
  })

  return data?.theatricalReleasesCollection?.items || []
}

const THEATRICAL_REGION_FRAGMENT = contentfulGQL/* GraphQL */ `
  fragment TheatricalRegionContent on TheatricalRegion {
    region
    releaseDate
    showComingSoon
    ticketPageEnabled
    pifPageEnabled
    hasTheatricalEnded
    hubPageEnabled
    hubPageHeroBackgroundVideo
    seoPifImage
    seoTicketsImage
    horizontalPosters
    trailerUrl
    goalSlug
    goalLineTitle
    enableRegionGoal
    goalLineTicketGoal
    costPerTicket
    shouldUseDefaultFlagForAllCountries
    defaultFlag
    countries
    name
    totalTicketsSold
    ticketMilestones
    initialShowtimeSearchDate
    shouldCollectEmailsForTicketPage
    shouldCollectEmailsForFreeTicketPage
    groupTicketsEnabled
    expressCheckoutInitialPerkId
    theatricalSlug
    freeTicketRequestAmount
    faQsCollection {
      items {
        ... on Faq {
          question
          answer {
            json
          }
        }
      }
    }
  }
`

export const getTheatricRegionBySlugAndRegionQuery = contentfulGQL/* GraphQL */ `
  ${THEATRICAL_REGION_FRAGMENT}
  query getTheatricalRegions($theatricalSlug: String, $regionName: String, $locale: String, $preview: Boolean) {
    theatricalRegionCollection(
      where: {
        AND: [
          { theatricalSlug: $theatricalSlug }
          { OR: [{ region: $regionName }, { countries_contains_some: [$regionName] }, { region: "US" }] }
        ]
      }
      locale: $locale
      preview: $preview
    ) {
      items {
        ...TheatricalRegionContent
      }
    }
  }
`
export const getTheatricRegionBySlugAndRegionQueryPifPage = contentfulGQL/* GraphQL */ `
  ${THEATRICAL_REGION_FRAGMENT}
  query getTheatricalRegionsPifPage($theatricalSlug: String, $regionName: String, $locale: String, $preview: Boolean) {
    theatricalRegionCollection(
      where: {
        AND: [
          { theatricalSlug: $theatricalSlug }
          { pifPageEnabled: true }
          { OR: [{ region: $regionName }, { countries_contains_some: [$regionName] }, { region: "US" }] }
        ]
      }
      locale: $locale
      preview: $preview
    ) {
      items {
        ...TheatricalRegionContent
      }
    }
  }
`

export const getTheatricRegionBySlugAndRegionQueryTicketPage = contentfulGQL/* GraphQL */ `
  ${THEATRICAL_REGION_FRAGMENT}
  query getTheatricalRegionsTicketPage(
    $theatricalSlug: String
    $regionName: String
    $locale: String
    $preview: Boolean
  ) {
    theatricalRegionCollection(
      where: {
        AND: [
          { theatricalSlug: $theatricalSlug }
          { ticketPageEnabled: true }
          { OR: [{ region: $regionName }, { countries_contains_some: [$regionName] }, { region: "US" }] }
        ]
      }
      locale: $locale
      preview: $preview
    ) {
      items {
        ...TheatricalRegionContent
      }
    }
  }
`

export const getTheatricalSupportRegions = contentfulGQL/* GraphQL */ `
  query getSupportRegions($theatricalSlug: String) {
    theatricalRegionCollection(where: { theatricalSlug: $theatricalSlug }) {
      items {
        region
        countries
        ticketPageEnabled
        pifPageEnabled
        filmDetails {
          ... on FilmDetails {
            filmSynopsis
            mediumDescription
          }
        }
      }
    }
  }
`

export const getTheatricalRegionsBySlugs = contentfulGQL/* GraphQL */ `
  query getTheatricalRegionsBySlugs($theatricalSlugs: [String]) {
    theatricalRegionCollection(where: { theatricalSlug_in: $theatricalSlugs }) {
      items {
        theatricalSlug
        countries
        region
        pifPageEnabled
        hasTheatricalEnded
      }
    }
  }
`

const getTheatricalMoviePosters = contentfulGQL/* GraphQL */ `
  query getTheatricalMovies($regionName: String) {
    theatricalRegionCollection(
      where: {
        AND: [
          { hasTheatricalEnded: false, ticketPageEnabled: true, verticalPosters_exists: true }
          { OR: [{ region: $regionName }, { countries_contains_some: [$regionName] }] }
        ]
      }
    ) {
      items {
        region
        verticalPosters
        projectName
        theatricalSlug
        releaseDate
        filmDetails {
          ... on FilmDetails {
            filmSynopsis
            mediumDescription
          }
        }
      }
    }
  }
`

const getTheatricReleaseBySlugQuery = contentfulGQL/* GraphQL */ `
  query getTheatricalReleaseBySlug($theatricalSlug: String, $preview: Boolean, $locale: String) {
    theatricalReleasesCollection(
      limit: 1
      where: { theatricalSlug: $theatricalSlug }
      preview: $preview
      locale: $locale
    ) {
      items {
        blacklistedVenueIDs
        theatricalSlug
        theatricalName
        description
        maxDaysToShow
        projectSlug
        releaseDate
        endDate
        theme
        horizontalPosters
        verticalPosters
        fathomEventsUrl
        fandangoUrl
        seoImages
        jsonLd
        noSearchResultsMessages
        seoTitle
        seoDescription
        hypeBackground
        logo
        hypePartyCode
        hypePinnedMessage {
          json
        }
        sceneData
        shiftPremiere
        goalLineSlug
        goalLineTitle
        initialChatMessages
        emergencyFandango
        emergencyAtom
        trailer {
          url
          guid
          slug
          name
          videoType
          subtitle
          title
          thumbnail
        }
        youtubeTrailerUrl
        criticsCollection {
          items {
            ... on Critics {
              icon
              company
              rating
            }
          }
        }
        filmDetails {
          ... on FilmDetails {
            filmSynopsis
            mediumDescription
            shortDescription
            rating
            duration
          }
        }
      }
    }
  }
`

export async function getTheatricalReleaseBySlug({
  theatricalSlug,
  opts,
  regionCode,
  page,
}: {
  theatricalSlug: string
  opts?: ContentfulClientOptions
  regionCode?: string
  page?: 'ticket' | 'pif'
}): Promise<TheatricalRelease | undefined> {
  const contentfulClient = getContentfulClient(opts)
  const theatricalRegionQuery = getTheatricalRegionQuery(page)
  const { data } = await contentfulClient.query<
    CMSTheatricReleaseCollection,
    { theatricalSlug: string; preview?: boolean }
  >({
    query: getTheatricReleaseBySlugQuery,
    variables: {
      theatricalSlug,
      preview: opts?.preview,
    },
    fetchPolicy: 'network-only',
  })

  const { data: regionData } = await contentfulClient.query<
    CMSTheatricRegionCollection,
    { theatricalSlug: string; regionName?: string; preview: boolean }
  >({
    query: theatricalRegionQuery,
    variables: {
      theatricalSlug,
      regionName: regionCode ?? TheatricalRegions.US,
      preview: opts?.preview ?? false,
    },
    fetchPolicy: 'network-only',
  })

  const { data: moviePosterData } = await contentfulClient.query<
    CMSTheatricRegionMovieCollection,
    { regionName: string }
  >({
    query: getTheatricalMoviePosters,
    variables: {
      regionName: regionCode ?? TheatricalRegions.US,
    },
    fetchPolicy: 'network-only',
  })

  const { data: supportedTheatricalRegions } = await contentfulClient.query<
    CMSTheatricRegionCollection,
    { theatricalSlug: string }
  >({
    query: getTheatricalSupportRegions,
    variables: {
      theatricalSlug,
    },
    fetchPolicy: 'network-only',
  })

  if (
    regionData?.theatricalRegionCollection?.items?.length > 0 &&
    moviePosterData?.theatricalRegionCollection?.items?.length > 0
  ) {
    return extractResponse(data, regionData, supportedTheatricalRegions, moviePosterData)
  }
  if (regionData?.theatricalRegionCollection?.items?.length > 0) {
    return extractResponse(data, regionData, supportedTheatricalRegions)
  }
  return extractResponse(data)
}

/* Extract out the extra structure(s) caused by using CMS for the data model */
function extractResponse(
  data: CMSTheatricReleaseCollection,
  regionData?: CMSTheatricRegionCollection,
  supportedRegions?: CMSTheatricRegionCollection,
  currentTheatricalMovies?: CMSTheatricRegionMovieCollection,
): TheatricalRelease | undefined {
  const theatricalReleases = data?.theatricalReleasesCollection?.items || []

  if (theatricalReleases?.length !== 1) {
    return
  }

  const { horizontalPosters, verticalPosters, seoImages, noSearchResultsMessages, hypeBackground, logo, ...rest } =
    theatricalReleases[0]

  return {
    ...rest,
    regionCollection: regionData ? regionData?.theatricalRegionCollection?.items : [],
    currentTheatricalMovies: currentTheatricalMovies ? currentTheatricalMovies?.theatricalRegionCollection?.items : [],
    supportedTheatricalRegions: supportedRegions ? supportedRegions?.theatricalRegionCollection?.items : [],
    horizontalPoster: horizontalPosters?.[0] ?? null,
    verticalPoster: verticalPosters?.[0] ?? null,
    seoImage: seoImages?.[0] ?? null,
    hypeBackground: hypeBackground?.[0] ?? null,
    logo: logo?.[0] ?? null,
    noSearchResultsMessages: Array.isArray(noSearchResultsMessages) ? noSearchResultsMessages : [],
  }
}
