import { gql as contentfulGQL } from '@apollo/client'
import { GetFloatingCallToActionQuery } from '@/types/codegen-contentful'

export type FloatingCallToActionType = NonNullable<
  NonNullable<NonNullable<GetFloatingCallToActionQuery>['callToActionCollection']>['items']
>[number]

export const GET_FLOATING_CALL_TO_ACTION_QUERY = contentfulGQL/* GraphQL */ `
  query getFloatingCallToAction($locale: String, $preview: Boolean, $region: String) {
    callToActionCollection(
      limit: 1
      locale: $locale
      order: sys_publishedAt_DESC
      preview: $preview
      where: { type: "Floating", OR: [{ region_exists: false }, { region_contains_all: [$region] }] }
    ) {
      items {
        buttonHref
        buttonIcon
        buttonText
        description
        descriptionColor
        logoAltText
        logoHeight
        logoUrl
        logoWidth
        title
        trailerUrl
        backgroundImage
        guildBackgroundImage
        guildButtonText
      }
    }
  }
`
