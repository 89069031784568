import React from 'react'
import { paths } from '@/constants'
import { useTranslate } from '@/utils/translate/translate-client'
import { ShowHero } from '../ShowHero'

export const FeaturedShow: React.FC = () => {
  const { t } = useTranslate('home')

  const cloudinaryImgPaths = {
    mobile:
      'https://images.angelstudios.com/image/upload/v1698960267/studio-app/catalog/b67d120e-45c9-4173-91b9-a140cd275ce2',
    tablet:
      'https://images.angelstudios.com/image/upload/v1698960273/studio-app/catalog/f17ebe32-aca9-4bb7-ac8e-0307e62e14b0',
    desktop:
      'https://images.angelstudios.com/image/upload/v1698960273/studio-app/catalog/f17ebe32-aca9-4bb7-ac8e-0307e62e14b0',
  }

  return (
    <ShowHero
      cloudinaryImgPaths={cloudinaryImgPaths}
      imageClassName="rounded-lg bg-cover"
      showTitle={t('youngDavidTitle', 'Young DAVID', { ns: 'common' })}
      showDescription={t(
        'youngDavidFeaturedDescription',
        'Before he became a king, Young DAVID was just a young boy who loved his sheep and his lyre.',
      )}
      ctaPath={paths.watch.youngDavid}
      ctaText={t('watchNow', 'Watch Now', { ns: 'common' })}
    />
  )
}
