import React from 'react'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { FaqAccordion } from '@/organisms/FaqAccordion'
import { Faq } from '@/services/FaqService'

interface FaqSectionProps {
  faqs: Faq[]
}

export const FaqSection: React.FC<FaqSectionProps> = ({ faqs }) => {
  return (
    <>
      {faqs && faqs.length > 0 && (
        <section role="complementary" className="relative w-full py-20 md:pb-40 md:pt-0">
          <div className="relative w-full">
            <PaddedContainer className="md:pt-48">
              <FaqAccordion faqs={faqs} />
            </PaddedContainer>
          </div>
        </section>
      )}
    </>
  )
}
