import React from 'react'
import classNames from 'classnames'
import CarouselNav from '@/atoms/CarouselNav'
import { Image } from '@/atoms/Image'
import { AngelLogo } from '@/atoms/Logos/AngelLogo'
import { HeadingMD, ParagraphXL } from '@/atoms/Text'
import { useDimensions } from '@/utils/ScreenUtils'
import { useSafeAnalytics } from '@/utils/analytics'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import useCarousel from '@/utils/useCarousel'

type AngelCommunityType = {
  imageUrl?: string
  href?: string
  t: TranslateFunction
}

export const AngelCommunity: React.FC = () => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [freeScrollerRef, { canScrollBack, canScrollForward, scrollBack, scrollForward }] =
    useCarousel<HTMLDivElement>()
  const { width: containerWidth } = useDimensions(containerRef)
  const { t } = useTranslate('home')

  const leftPadding = containerWidth && containerWidth > 1560 && (containerWidth - 1550) / 2 + 112

  const AngelCommunity = [
    {
      imageUrl: '/v1719353109/angel-studios/landing/Angel%20Community/image_12.png',
      href: '/watch/cabrini/community/stories/3442252',
    },
    {
      imageUrl: '/v1719353109/angel-studios/landing/Angel%20Community/image_13.png',
      href: '/watch/sound-of-freedom/community/stories/e1bbc555-bbe7-4d81-a651-011bb680a79c',
    },
    {
      imageUrl: '/v1719353109/angel-studios/landing/Angel%20Community/image_14.png',
      href: '/watch/young-david/community/stories/f1ac70b0-54af-404d-9f7f-f9d9812aed67',
    },
    {
      imageUrl: '/v1719353109/angel-studios/landing/Angel%20Community/image_15.png',
      href: '/watch/after-death/community/stories/d309dcfe-1d02-4d7a-99cb-79fbeb5b7be8',
    },
    {
      imageUrl: '/v1719353109/angel-studios/landing/Angel%20Community/image_16.png',
      href: '/watch/his-only-son/community/stories/f2d96707-2c37-415a-9134-cc124d8a3efb',
    },
  ]

  return (
    <section
      ref={containerRef}
      className="relative -my-1 flex h-fit w-full flex-col items-start justify-center bg-core-gray-950 py-1 text-white"
    >
      <div className="w-full flex-col items-center justify-center">
        <div className="relative flex w-full flex-col items-center gap-4 px-4 pt-24 text-center md:items-start md:px-8 md:text-left lg:px-28 lg:pt-28 2xl:mx-auto 2xl:w-[1550px]">
          <AngelLogo />
          <HeadingMD
            className="md:photon-heading-lg lg:photon-display max-w-[332px] md:max-w-[350px] lg:max-w-[579px]"
            weight="bold"
          >
            {t('bePartAngelCommunity', 'Be part of the Angel Community')}
          </HeadingMD>
          <ParagraphXL className="md:max-w-auto max-w-[346px] md:max-w-[600px]" weight="medium" color="core-gray-500">
            {t(
              'whatPeopleSayAboutAngel',
              'See what people have to say about Angel Studios and the films and shows that are changing lives.',
            )}
          </ParagraphXL>
        </div>
        <div className="relative w-full">
          <div
            className="relative flex w-full snap-x snap-mandatory flex-row gap-8 overflow-scroll px-4 scrollbar-none md:mb-[118px] md:px-8 lg:px-28"
            style={{ paddingLeft: leftPadding ?? 0 }}
            ref={freeScrollerRef}
          >
            {AngelCommunity.map((data) => {
              return <AngelCard key={data.href} {...data} t={t} />
            })}
          </div>
          <CarouselNav
            onClick={scrollBack}
            active={canScrollBack}
            orientation="horizontal"
            direction="back"
            className={classNames('ml-4', { 'opacity-75 hover:opacity-100': canScrollBack })}
            theme="dark"
          />
          <CarouselNav
            onClick={scrollForward}
            active={canScrollForward}
            orientation="horizontal"
            direction="forward"
            className={classNames('mr-4', { 'opacity-75 hover:opacity-100': canScrollForward })}
            theme="dark"
          />
        </div>
      </div>
    </section>
  )
}

const AngelCard: React.FC<AngelCommunityType> = ({ imageUrl, href, t }) => {
  const { track } = useSafeAnalytics()

  return (
    <a
      href={href}
      target="_blank"
      onClick={() => track('Watch Home Community Story Clicked')}
      className="flex snap-center flex-col justify-between py-8 text-white"
    >
      <div className="overflow-hidden rounded-2xl shadow-dark-3">
        <Image
          className="flex min-h-[480px] min-w-[332px]"
          width={332}
          height={480}
          src={imageUrl ?? ''}
          alt={t('communityStory', 'Community Story')}
        />
      </div>
    </a>
  )
}
