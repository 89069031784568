import React, { useCallback } from 'react'
import { LinkButton } from '@/atoms/Button'
import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { LivestreamIcon } from '@/atoms/Icons/LivestreamIcon'
import { PlaySquareIcon } from '@/atoms/Icons/PlaySquareIcon'
import { StoreIcon } from '@/atoms/Icons/StoreIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { Image } from '@/atoms/Image'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { HeadingMD, ParagraphLG, TitleMD, TitleSM } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { useLanding } from '@/contexts/LandingContext'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useSafeAnalytics } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

const buttonClassNames = 'justify-center min-w-[121px] px-3 py-[11px] w-full md:w-auto'

export const JoinTheMovement: React.FC = () => {
  const { t } = useTranslate('home')
  const { track } = useSafeAnalytics()
  const { members } = useLanding()

  const handleLoginClicked = useCallback(() => {
    track('Home Movement Guild Join Clicked')
  }, [track])

  const movementImgPaths = {
    mobile:
      'https://images.angelstudios.com/image/upload/v1716990736/angel-studios/landing/Join%20The%20Movement/guild_-_428px.png',
    tablet:
      'https://images.angelstudios.com/image/upload/v1716990831/angel-studios/landing/Join%20The%20Movement/guild_-_1024px.png',
    desktop:
      'https://images.angelstudios.com/image/upload/v1716990893/angel-studios/landing/Join%20The%20Movement/guild_-_1112px.png',
    widerDesktop:
      'https://images.angelstudios.com/image/upload/v1716991075/angel-studios/landing/Join%20The%20Movement/guild_-_1728px.png',
    ultrawide:
      'https://images.angelstudios.com/image/upload/v1716991251/angel-studios/landing/Join%20The%20Movement/guild_-_3440px_-_UltraWide.png',
  }
  const guildMembersCount = members?.toLocaleString() ?? '300,000'

  return (
    <section className="relative flex h-full w-full flex-col items-center justify-center bg-core-gray-950 px-4 pb-12 pt-36 md:px-0 md:py-52 xl:py-60 2xl:flex-row 2xl:justify-between 2xl:px-52 3xl:justify-around 3xl:py-72 4xl:py-96">
      <ResponsiveBackgroundImage
        src={movementImgPaths.desktop}
        srcUrls={{
          xs: movementImgPaths.mobile,
          sm: movementImgPaths.mobile,
          md: movementImgPaths.tablet,
          lg: movementImgPaths.desktop,
          xl: movementImgPaths.widerDesktop,
          '2xl': movementImgPaths.widerDesktop,
          '3xl': movementImgPaths.ultrawide,
        }}
        className={'h-full w-full bg-cover bg-center bg-no-repeat'}
      />
      <div className="relative mt-24 flex w-full flex-col gap-8 py-4 md:mt-0 md:w-fit md:max-w-[500px] md:gap-10 md:rounded-3xl md:bg-[#383838]/60 md:p-12 md:backdrop-blur-lg 2xl:max-w-[616px] 2xl:gap-12">
        <div className="flex flex-col items-center gap-4 text-center text-white 2xl:items-start 2xl:text-left">
          <Image
            alt={t('guildIconAlt', 'Guild Icon')}
            src={getCloudinaryImageUrl({
              path: '/v1716997759/angel-studios/landing/Join%20The%20Movement/Angel_Guild_logo_arc.png',
            })}
            width={78}
            height={96}
            className="2xl:hidden"
          />
          <Image
            alt={t('guildIconAlt', 'Guild Icon')}
            src={getCloudinaryImageUrl({
              path: '/v1716997788/angel-studios/landing/Join%20The%20Movement/Angel_Guild_logo_arc_wide.png',
            })}
            width={192}
            height={56}
            className="hidden 2xl:block"
          />
          <HeadingMD weight="bold" className="md:photon-heading-lg 2xl:photon-display">
            {t('joinMovement', 'Join the Movement')}
          </HeadingMD>
          <ParagraphLG weight="medium" className="md:photon-paragraph-xl">
            {t(
              'membersApproveContentCount',
              'Over {{ guildMembersCount }} members approve content that inspires and uplifts the world.',
              { guildMembersCount },
            )}
          </ParagraphLG>
        </div>
        <div className="flex flex-col gap-6 text-left text-core-gray-400">
          <TitleMD weight="medium">{t('membersAlsoReceive', 'Guild members also receive')}:</TitleMD>
          <div className="flex flex-row gap-4 text-white">
            <PlaySquareIcon color="white" size={24} />
            <TitleSM className="md:photon-title-lg">{t('earlyAccess', 'Early Access')}</TitleSM>
          </div>
          <div className="flex flex-row gap-4 text-white">
            <TicketIcon color="white" size={24} />
            <TitleSM className="md:photon-title-lg">{t('angelMovieTickets', 'Angel Movie Tickets')}</TitleSM>
          </div>
          <div className="flex flex-row gap-4 text-white">
            <LivestreamIcon color="white" size={24} />
            <TitleSM className="md:photon-title-lg">{t('guildOnlyLivestreams', 'Guild Only Livestreams')}</TitleSM>
          </div>
          <div className="flex flex-row gap-4 text-white">
            <StoreIcon color="white" size={24} />
            <TitleSM className="md:photon-title-lg">{t('merchDiscounts', 'Merch Discounts')}</TitleSM>
          </div>
        </div>
        <SignupButton buttonClassNames={buttonClassNames} handleLoginClicked={handleLoginClicked} />
      </div>
      <div />
      <div />
      <div />
      <div />
    </section>
  )
}

const SignupButton = ({
  handleLoginClicked,
  buttonClassNames,
}: {
  handleLoginClicked: () => void
  buttonClassNames: string
}) => {
  const { t } = useTranslate('home')

  return (
    <div className="w-full">
      <LinkButton href={paths.guild.join} variant="white" className={buttonClassNames} onClick={handleLoginClicked}>
        <div className="relative flex w-full flex-row items-center justify-center gap-2">
          <GuildIcon size={24} color1="current" className="fill-inherit" />
          {t('joinTheGuild', 'Join the Guild')}
        </div>
      </LinkButton>
    </div>
  )
}
