import React, { useMemo } from 'react'
import { Collection, GuildHomeHero } from '@/types/codegen-contentful'
import { ReactFCC } from '@/types/react'

interface LandingContextProps {
  guildHomeHero?: GuildHomeHero
  watchForFree?: Collection
  children: React.ReactNode
  members: number
}

interface LandingContextState {
  guildHomeHero?: GuildHomeHero
  watchForFree?: Collection
  members: number
}

const defaultValue = {
  guildHomeHero: undefined,
  watchForFree: undefined,
  members: 360000,
}

export const LandingContext = React.createContext<LandingContextState>(defaultValue)

export const LandingProvider: ReactFCC<LandingContextProps> = ({ children, guildHomeHero, watchForFree, members }) => {
  const value = useMemo(() => {
    return {
      guildHomeHero,
      watchForFree,
      members,
    }
  }, [guildHomeHero, watchForFree, members])

  return <LandingContext.Provider value={value}>{children}</LandingContext.Provider>
}

export const useLanding = () => React.useContext(LandingContext)
