import { gql as contentfulGQL } from '@apollo/client'
import { GetMoviePagesQuery, Project } from '@/types/codegen-contentful'
import { getContentfulClient } from '../ApolloClient'

const getMoviePagesQuery = contentfulGQL/* GraphQL */ `
  query getMoviePages {
    projectCollection(where: { filmDetails_exists: true }) {
      items {
        filmDetails(where: { willHaveTheatricalRelease: true }) {
          willHaveTheatricalRelease
        }
        slug
        sys {
          id
          publishedAt
        }
      }
    }
  }
`

export async function getMoviePages(): Promise<Project[]> {
  const contentfulClient = getContentfulClient()

  const { data } = await contentfulClient.query<GetMoviePagesQuery>({
    query: getMoviePagesQuery,
  })

  return (data?.projectCollection?.items.filter((project) => project?.filmDetails) as Project[]) || []
}
