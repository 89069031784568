import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { useLanding } from '@/contexts/LandingContext'
import { useRegionContext } from '@/contexts/RegionContext'
import { FeaturedProjectSection } from '@/organisms/FeaturedProjectSection'
import { InvestmentBanner } from '@/organisms/InvestmentBanner'
import { getContentfulClient } from '@/services/ApolloClient'
import { getFeaturedProjectsQuery } from '@/services/CmsService'
import { Faq } from '@/services/FaqService'
import { useGuildUser } from '@/services/GuildUserService'
import { GetFeaturedProjectsQuery, InvestmentBanner as InvestmentBannerContent } from '@/types/codegen-contentful'
import { useLocale } from '@/utils/LocaleUtil'
import { AppPromoSection } from '@/views/LandingView/AppPromoSection'
import { CreatePromoSection } from '@/views/LandingView/CreatePromoSection'
import { FeaturedShowSection } from '@/views/LandingView/FeaturedShowSection'
import { AngelCommunity } from './AngelCommunitySection'
import { FaqSection } from './FaqSection'
import { JoinTheMovement } from './JoinTheMovementSection'
import { MainHeroSection } from './MainHeroSection'
import { WatchForFree } from './WatchForFreeSection'

interface LandingView {
  faqs: Faq[]
  investmentBanner?: InvestmentBannerContent
  isLoggedIn: boolean
  preview: boolean
}

export const LandingView: React.FC<LandingView> = ({ faqs, investmentBanner, isLoggedIn, preview }) => {
  const { locale } = useLocale()
  const { watchForFree } = useLanding()
  const { isGuildMember } = useGuildUser()
  const { loading: loadingRegion, region } = useRegionContext()
  const client = getContentfulClient({ locale, preview, region })
  const isUSorCanada = region === 'US' || region === 'CA'
  const [getFeaturedProjects, { data: featuredProjectsData, loading: loadingFeaturedProjects }] =
    useLazyQuery<GetFeaturedProjectsQuery>(getFeaturedProjectsQuery, {
      client,
    })

  useEffect(() => {
    if (!loadingRegion && region) {
      getFeaturedProjects({ variables: { locale, preview, region } })
    }
  }, [getFeaturedProjects, loadingRegion, locale, preview, region])

  const firstFeaturedProjectId = featuredProjectsData?.featuredProjectCollection?.items?.[0]?.sys?.id
  const secondFeaturedProjectId = featuredProjectsData?.featuredProjectCollection?.items?.[1]?.sys?.id

  return (
    <div className="mx-auto flex flex-col bg-core-gray-950 text-white">
      <MainHeroSection
        client={client}
        isLoggedIn={isLoggedIn}
        loading={false}
        preview={preview}
        region={region}
        isGuildMember={isGuildMember}
      />
      {investmentBanner && <InvestmentBanner {...investmentBanner} className="my-6 md:my-8 lg:my-12" />}
      <When condition={isUSorCanada && !loadingFeaturedProjects && firstFeaturedProjectId}>
        <FeaturedProjectSection featuredProjectId={firstFeaturedProjectId as string} />
      </When>
      <When condition={Boolean(watchForFree)}>
        <WatchForFree watchForFree={watchForFree} isLoggedIn={isLoggedIn} />
      </When>
      <LazyHydrate whenVisible>
        <When condition={!isGuildMember}>
          <JoinTheMovement />
        </When>
      </LazyHydrate>
      <LazyHydrate whenVisible>
        <AngelCommunity />
      </LazyHydrate>
      <div className="bg-core-gray-950 pt-4">
        <AppPromoSection />
      </div>

      <When condition={isUSorCanada && !loadingFeaturedProjects && secondFeaturedProjectId}>
        <FeaturedProjectSection featuredProjectId={secondFeaturedProjectId as string} />
      </When>

      <div className="bg-core-gray-950 pb-12">
        <FeaturedShowSection />
      </div>

      <CreatePromoSection />

      <FaqSection faqs={faqs} />
    </div>
  )
}
