import React from 'react'
import { HeartIconThick } from '@/atoms/Icons/HeartIconThick'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { EyebrowMD } from '@/atoms/Text'
import { FeaturedShow } from '@/organisms/FeaturedShow'
import { useTranslate } from '@/utils/translate/translate-client'

export const FeaturedShowSection: React.FC = () => {
  const { t } = useTranslate('home')

  return (
    <section role="complementary" className="w-full pt-20">
      <div className="mb-12 flex flex-row items-center justify-center bg-core-gray-950 font-bold uppercase">
        <hr className="mb-16s border-top w-full border-solid border-white/[15%]" />
        <div className="mx-4 flex items-center justify-center space-x-2">
          <HeartIconThick color="oxide" />
          <EyebrowMD weight="bold" className="whitespace-nowrap">
            {t('featuredShow', 'Featured Show')}
          </EyebrowMD>
        </div>
        <hr className="border-top w-full border-solid border-white/[15%]" />
      </div>
      <PaddedContainer>
        <FeaturedShow />
      </PaddedContainer>
    </section>
  )
}
