import { useCallback, useState } from 'react'
import { When } from 'react-if'
import { CircleCloseIcon } from '@/atoms/Icons/CircleCloseIcon'
import { Image } from '@/atoms/Image'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { FloatingCallToActionType } from '@/services/CmsService/CallToAction'
import { buildCloudinaryImagePathFromContentfulObject, getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useSafeTrack } from '@/utils/analytics'

interface FloatingCallToActionImageProps {
  floatingCta: FloatingCallToActionType
  onClick: () => void
  isGuildMember: boolean
}

export const FloatingCallToActionImage: React.FC<FloatingCallToActionImageProps> = ({
  floatingCta,
  onClick,
  isGuildMember,
}) => {
  const [showCta, setShowCta] = useState(true)
  const track = useSafeTrack()

  const handleClick = useCallback(() => {
    track('Floating CTA Clicked')
    onClick()
  }, [onClick, track])

  const handleClose = () => {
    setShowCta(false)
  }

  return (
    <When condition={showCta}>
      <div className="group-floating-cta fixed inset-x-0 bottom-0 z-40 max-h-[350px] max-w-[350px] gap-4 bg-transparent shortDesktop:max-h-[250px] shortDesktop:max-w-[250px]">
        <div className="relative">
          <When condition={isGuildMember}>
            <GBFadeInExperiment defaultValue={false} experimentName="bonhoeffer_floating_theatrical_cta">
              <ExperimentVariation variation={true}>
                <Image
                  src={getCloudinaryImageUrl({
                    path: `/v1730211786/angel-studios/landing/Corner%20CTA/Bonhoeffer_Corner_Pin_angel_2.png`,
                  })}
                  alt={floatingCta?.description ?? 'Floating Call to Action'}
                  height={350}
                  className="pointer-events-none cursor-default"
                  width={350}
                />
              </ExperimentVariation>
              <ExperimentVariation variation={false}>
                {floatingCta?.guildBackgroundImage?.length ? (
                  <Image
                    src={buildCloudinaryImagePathFromContentfulObject(floatingCta?.guildBackgroundImage)}
                    alt={floatingCta?.description ?? 'Floating Call to Action'}
                    height={350}
                    className="pointer-events-none cursor-default"
                    width={350}
                  />
                ) : (
                  floatingCta?.backgroundImage?.length && (
                    <Image
                      src={buildCloudinaryImagePathFromContentfulObject(floatingCta?.backgroundImage)}
                      alt={floatingCta?.description ?? 'Floating Call to Action'}
                      height={350}
                      className="pointer-events-none cursor-default"
                      width={350}
                    />
                  )
                )}
              </ExperimentVariation>
              <ExperimentVariation default>
                {floatingCta?.guildBackgroundImage?.length ? (
                  <Image
                    src={buildCloudinaryImagePathFromContentfulObject(floatingCta?.guildBackgroundImage)}
                    alt={floatingCta?.description ?? 'Floating Call to Action'}
                    height={350}
                    className="pointer-events-none cursor-default"
                    width={350}
                  />
                ) : (
                  floatingCta?.backgroundImage?.length && (
                    <Image
                      src={buildCloudinaryImagePathFromContentfulObject(floatingCta?.backgroundImage)}
                      alt={floatingCta?.description ?? 'Floating Call to Action'}
                      height={350}
                      className="pointer-events-none cursor-default"
                      width={350}
                    />
                  )
                )}
              </ExperimentVariation>
            </GBFadeInExperiment>
          </When>
          <When condition={!isGuildMember}>
            <GBFadeInExperiment defaultValue={false} experimentName="bonhoeffer_floating_theatrical_cta">
              <ExperimentVariation variation={true}>
                <Image
                  src={getCloudinaryImageUrl({
                    path: `/v1730211786/angel-studios/landing/Corner%20CTA/Bonhoeffer_Corner_Pin_angel_2.png`,
                  })}
                  alt={floatingCta?.description ?? 'Floating Call to Action'}
                  height={350}
                  className="pointer-events-none cursor-default"
                  width={350}
                />
              </ExperimentVariation>
              <ExperimentVariation variation={false}>
                {floatingCta?.backgroundImage?.length && (
                  <Image
                    src={buildCloudinaryImagePathFromContentfulObject(floatingCta?.backgroundImage)}
                    alt={floatingCta?.description ?? 'Floating Call to Action'}
                    height={350}
                    className="pointer-events-none cursor-default"
                    width={350}
                  />
                )}
              </ExperimentVariation>
              <ExperimentVariation default>
                {floatingCta?.backgroundImage?.length && (
                  <Image
                    src={buildCloudinaryImagePathFromContentfulObject(floatingCta?.backgroundImage)}
                    alt={floatingCta?.description ?? 'Floating Call to Action'}
                    height={350}
                    className="pointer-events-none cursor-default"
                    width={350}
                  />
                )}
              </ExperimentVariation>
            </GBFadeInExperiment>
          </When>
          <a
            href={floatingCta?.buttonHref ?? '/tickets'}
            className="absolute bottom-[46px] left-[16px] h-14 w-60 cursor-pointer"
            onClick={handleClick}
          />
          <div
            className="absolute left-2 top-14 z-[5000] cursor-pointer rounded-full bg-black opacity-0 transition-all duration-300 ease-in-out group-floating-cta-hover:opacity-100 hover:opacity-70"
            onClick={handleClose}
          >
            <CircleCloseIcon color="core-gray-500" size={24} className="cursor-pointer" />
          </div>
        </div>
      </div>
    </When>
  )
}
